import React, { Component } from "react";
import MainLayout from "../../Components/Layouts/MainLayoutV2";
import CardBlock from "../../Components/CardBlock";
import { Link, Redirect } from "react-router-dom";
import Constants from "../../Utils/Constants";
import APICall from "../../Utils/APICall";
import Spinner from "../../Utils/Spinner";
import SpinnerButton from "../../Utils/SpinnerButton";
import Toastr from "../../Utils/Toastr";
import Modal from "react-modal";
import DataAPI from "../../Utils/DataAPI";
import Functions from "../../Utils/Functions";
import NewDiseaseReport from "../../Components/DiseaseReporting/NewDiseaseReport";
import { DatePicker, DatePickerInput } from "rc-datepicker";
import { Line, Bar, Doughnut } from "react-chartjs-2";
import RanchAnimalMonthlyList from "../../Components/RanchAnimalMonthlyList";
import PublicWithTopStripBackground from "../../Components/Layouts/PublicWithTopStripBackground";

export default class FetchAnimalDetailsPublic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      animalTag: "", // Store the animal tag
      diseaseListLoading: false,
      loading: false,
      data: null,
      errorMessage: null,
      step: 1,
      currentCheckin: {},
      distributors: [],
      redirectUrl: null,
      payload: {},
      investors: [],
      validateLoading: false,
      animal: null,
      modalIsOpen: false,
      destinations: [],
      animalSlaughterInfo: null,
      animalMonthlyInfo: null,
      lineData: {
        labels: ["1st", "2nd"],
        datasets: [
          {
            label: "# of Votes",
            data: [],
            fill: true,
            backgroundColor: "rgb(131, 183, 57)",
            borderColor: "rgba(0, 110, 71, 0.45)",
          },
        ],
      }
    };

    this.formControl = this.formControl.bind(this);
    this.resetDiseaseList = this.resetDiseaseList.bind(this);
  }

  data = {
    labels: ["1", "2", "3", "4", "5", "6"],
    datasets: [
      {
        label: "# of Red Votes",
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: "rgb(255, 99, 132)",
      },
      {
        label: "# of Blue Votes",
        data: [2, 3, 20, 5, 1, 4],
        backgroundColor: "rgb(54, 162, 235)",
      },
    ],
  };

  options = {
    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          barPercentage: 0.9,
          categoryPercentage: 0.2,
          gridLines: {
            color: "rgba(0, 0, 0, 0)",
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  doughNutOptions = {
    legend: {
      display: false,
    },
  };

  doughNutData = {
    labels: [
      "Abattoir 1",
      "Abattoir 2",
      "Abattoir 3",
      "Abattoir 4",
      "Abattoir 5",
      "Abattoir 6",
    ],
    datasets: [
      {
        label: "# of Votes",
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  lineData = {
    labels: ["1st", "2nd"],
    datasets: [
      {
        label: "# of Votes",
        data: this?.state?.weightList,
        fill: true,
        backgroundColor: "rgb(131, 183, 57)",
        borderColor: "rgba(0, 110, 71, 0.45)",
      },
    ],
  };

  lineOptions = {
    elements: {
      point: {
        radius: 0,
      },
    },
    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            color: "rgba(0, 0, 0, 0)",
          },
        },
      ],
      yAxes: [
        {
          display: false,
          gridLines: {
            color: "rgba(0, 0, 0, 0)",
          },
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };
  componentDidMount() {
    // this.getLastCheckin();
    Modal.setAppElement("body");
  }

  componentDidMount() {
    // Extract query parameters using URLSearchParams
    const queryParams = new URLSearchParams(window.location.search);
    const animalTag = queryParams.get("animal-tag");


    console.log(animalTag, "here")
    // Set the state with the extracted animal tag
    if (animalTag) {
      this.setState({ animalTag });
      this.updateProp(animalTag, "tagNumber")

      this.validateTagNumber();
      this.fetchBirthInformation();
      this.fetchMonthlyInformation();
    }
  }

  async validateTagNumber() {
    this.setState({ validateLoading: true, animal: null });
    let animal = await DataAPI.findAnimal(this.state.payload?.tagNumber);
    if (animal) {
      this.setState({ animalSlaughterInfo: animal });
      // this.updateProp(animal.specie, "animalSpecie");
      // this.updateProp(
      //   this.state.payload.tagNumber.toUpperCase(),
      //   "identificationMark"
      // );
    } else {
      console.log("error", "Invalid animal tag number");
    }
    this.setState({ validateLoading: false });
  }

  async fetchBirthInformation() {
    this.setState({ validateLoading: true, animal: null });
    let animal = await DataAPI.findAnimalBirthInformation(this.state.payload?.tagNumber);
    if (animal) {
      this.setState({ animal: animal });
      this.updateProp(animal.specie, "animalSpecie");
      this.updateProp(
        this.state.payload.tagNumber.toUpperCase(),
        "identificationMark"
      );
    } else {
      console.log("success", "This animal's delivery hasnt been taken");
    }
    this.setState({ validateLoading: false });
  }

  async fetchMonthlyInformation() {
    this.setState({ validateLoading: true, animal: null });
    let animal = await DataAPI.findAnimalMonthlyRecords(this.state.payload?.tagNumber);
    if (animal) {

      this.setState({
        lineData: {
          labels: Array.from({ length: animal.length }, (_, index) => `Mth ${index + 1}`),
          datasets: [
            {
              label: "# of Votes",
              data: animal.map((item) => item.weight),
              fill: true,
              backgroundColor: "rgb(131, 183, 57)",
              borderColor: "rgba(0, 110, 71, 0.45)",
            },
          ],
        }
      });

      console.log(this.state.weightList)

    } else {
      console.log("success", "This animal's delivery hasnt been taken");
    }
    this.setState({ validateLoading: false });
  }

  addDestination = () => {
    let destinations = this.state.destinations;
    if (!destinations) destinations = [];

    if (this.state.newDestination && this.state.newDestination.trim()) {
      if (
        destinations.find(
          (a) =>
            a.toLowerCase() == this.state.newDestination.trim().toLowerCase()
        )
      ) {
        return;
      }
      destinations.push(this.state.newDestination.trim());
      this.setState({ destinations: destinations, newDestination: "" });
    }
  };

  removeDestination = (destination) => {
    let _destinations = this.state.destinations.filter((a) => a != destination);
    this.setState({ destinations: _destinations });
  };

  advanceStep1 = async () => {
    if (
      !this.state.payload?.animalSpecie ||
      !this.state.payload?.beefWeightInKg
    ) {
      Toastr("error", "Some required fields are not filled");
      return;
    }

    if (await this.checkWeight()) {
      this.setState({ step: 2 });
    }
  };

  getLastCheckin() {
    APICall("api/vets/lastcheckin", "get")
      .then((data) => {
        if (data.data.isSuccess) {
          let response = data.data;
          if (response.data) {
            this.setState({ currentCheckin: response.data }, () => {
              //investors are tied to abattoirs
              this.getInvestors();
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  checkWeight = async () => {
    if (!this.state.animal?.tagNumber || !this.state.payload?.beefWeightInKg) {
      Toastr("error", "Check that form is properly filled");
      return false;
    }

    let weightOk = false;
    await APICall(
      `api/certificates/checkweight/${this.state.animal.tagNumber}/${this.state.payload.beefWeightInKg}`,
      "post"
    )
      .then((data) => {
        if (data.data.isSuccess) {
          weightOk = true;
        } else {
          Toastr("error", data.data.message);
        }
      })
      .catch((error) => {
        Toastr("error", "Unable to continue, check your network");
      });

    return weightOk;
  };

  getInvestors() {
    APICall(
      `api/abattoirs/listInvestors/${this.state.currentCheckin.id}`,
      "get"
    )
      .then((data) => {
        if (data.data.isSuccess) {
          let response = data.data;
          if (response.data) {
            response.data.forEach((element) => {
              element.value = element.id;
              element.label = element.investorName;
            });
            this.setState({ investors: response.data });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  issueCertificate() {
    if (!this.state.destinations || this.state.destinations.length <= 0) {
      this.setState({
        loading: false,
        errorMessage: "Add at least one destination",
      });
      return;
    }
    let payload = this.state.payload;
    payload.destinations = this.state.destinations;
    payload.abattoirId = this.state.currentCheckin.id;
    payload.numberOfParts = parseInt(payload.numberOfParts) || 1;
    payload.beefWeightInKg = parseFloat(payload.beefWeightInKg);

    if (payload.paymentChannel?.toLowerCase() == "card") {
      payload.receiptNumber = "";
    }

    this.setState({ loading: true, errorMessage: null });
    APICall("api/certificates/issue", "post", payload)
      .then((data) => {
        if (data.data.isSuccess) {
          Toastr("success", "Certificate issued successfully");
          this.setState({
            redirectUrl: "/in/new-certificate-success/" + data.data.data.code,
          });
        } else {
          this.setState({ loading: false, errorMessage: data.data.message });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          loading: false,
          errorMessage: "Check your network connection",
        });
      });
  }

  modalStyle = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      overflow: "visible",
    },
  };

  updateProp(value, prop) {
    let payload = this.state.payload;
    if (!payload) payload = {};
    payload[prop] = value;
    this.setState({ payload: payload });
  }

  handleChange(option) {
    let payload = this.state.payload;
    if (!payload) payload = {};
    payload.distributorId = option.id;
    this.setState({ payload: payload });
  }

  formGroupRow({ colClass, children, extraChildren }) {
    return (
      <div className="form-group row">
        <div className={colClass}>{children}</div>
        {extraChildren && <div className={colClass}>{extraChildren}</div>}
      </div>
    );
  }

  formControl({ label, prop, inputType, required }) {
    if (!inputType) inputType = "text";
    let payload = this.state.payload;
    if (!payload) payload = {};

    return (
      <>
        <label className="small control-label font-weight-bold text-darker">
          {label} {required && <small className="text-danger">*</small>}
        </label>
        <input
          type={inputType}
          className="form-control form-control-sm"
          placeholder=""
          defaultValue={payload[prop]}
          onChange={(e) => this.updateProp(e.target.value, prop)}
        />
      </>
    );
  }

  resetDiseaseList() {
    this.setState({ modalIsOpen: false, diseaseListLoading: true }, () => {
      setTimeout(() => {
        this.setState({ diseaseListLoading: false });
      }, 1000);
    });
  }

  singleAnimalItem = ({ label, prop }) => {
    return (
      <>
        <p className="small">
          <strong className="text-muted">{label}</strong>
          <br />
          {this.state.animal[prop]}
          <hr />
        </p>
      </>
    );
  };

  animalBirthInformationDisplay = () => {
    return (
      <div className="row text-dark">
        <div className="col-6">
          {this.singleAnimalItem({ label: "Specie", prop: "specie" })}
        </div>
        <div className="col-6 text-right">
          {this.singleAnimalItem({ label: "Sub-specie", prop: "subSpecie" })}
        </div>

        <div className="col-6">
          {this.singleAnimalItem({ label: "Color", prop: "color" })}
        </div>
        <div className="col-6 text-right">
          {this.singleAnimalItem({ label: "Gender", prop: "gender" })}
        </div>
        <div className="col-6 ">
          <>
            <p className="small">
              <strong className="text-muted">Weight at Birth</strong>
              <br />
              {`${this.state.animal["weightAtBirth"]}kg`}              <hr />
            </p>
          </>
        </div>
        <div className="col-6 text-right">
          <>
            <p className="small">
              <strong className="text-muted">Weight at Slaughter</strong>
              <br />
              {this.state?.animalSlaughterInfo?.weight ? `${this.state?.animalSlaughterInfo?.weight}kg` : "-"}
              <hr />
            </p>
          </>
        </div>

        <div className="col-6">
          <>
            <p className="small">
              <strong className="text-muted">Date of Birth</strong>
              <br />

              {Functions.sqlDateString(this.state.animal["dateOfBirth"])}
              <hr />
            </p>
          </>
        </div>
        <div className="col-6 text-right">
          <>
            <p className="small">
              <strong className="text-muted">Date of Slaughter</strong>
              <br />
              {this.state?.animalSlaughterInfo?.dateCreated ? Functions.sqlDateString(this.state?.animalSlaughterInfo?.dateCreated) : "-"}
              <hr />
            </p>
          </>
        </div>


        <div className="col-6">
          <>
            <p className="small">
              <strong className="text-muted">State of Origin</strong>
              <br />
              {this.state.animal.user.state}
              <hr />
            </p>
          </>
        </div>
        <div className="col-6 text-right">
          <>
            <p className="small">
              <strong className="text-muted">Animal Owner</strong>
              <br />

              {this.state.animal.user.name ? this.state.animal.user.name : "-"}

              <hr />
            </p>
          </>
        </div>

        <div className="col-6">
          <>
            <p className="small">
              <strong className="text-muted">Ranch Address</strong>
              <br />
              {this.state.animal.user.operatingAddress ? this.state.animal.user.operatingAddress : "-"}
              <hr />
            </p>
          </>
        </div>

        <div className="col-6 text-right">
          <>
            <p className="small">
              <strong className="text-muted">Mode of Slaughter</strong>
              <br />
              {this.state?.animalSlaughterInfo?.modeOfSlaughter ? this.state?.animalSlaughterInfo?.modeOfSlaughter : "-"}
              <hr />
            </p>
          </>
        </div>

        <div className="col-6">
          <>
            <p className="small">
              <strong className="text-muted">Meat Distributor's Name</strong>
              <br />
              {this.state?.animalSlaughterInfo?.buyerName ? this.state?.animalSlaughterInfo?.buyerName : "-"}
              <hr />
            </p>
          </>
        </div>

        <div className="col-6 text-right">
          <>
            <p className="small">
              <strong className="text-muted">Vet's observations at slaughter</strong>
              <br />
              {this.state?.animalSlaughterInfo?.vetObservations ? this.state?.animalSlaughterInfo?.vetObservations : "-"}
              <hr />
            </p>
          </>
        </div>

      </div>
    );
  };


  render() {
    return this.state.redirectUrl ? (
      <Redirect to={this.state.redirectUrl} />
    ) : (
      <>
            <div className="">
              <Modal
                isOpen={this.state.modalIsOpen}
                contentLabel=""
                style={Constants.defaultModalStyle}
                onRequestClose={() => this.resetDiseaseList()}
              >
                <div
                  className="mymodal-wrapper"
                  style={{ maxHeight: "450px", overflowY: "scroll" }}
                >
                  <NewDiseaseReport
                    returnAction={() => this.resetDiseaseList()}
                    tagNumber={this.props.tagNumber || this.state.payload?.tagNumber}
                  />
                </div>
              </Modal>
              <MainLayout
        title={
          <>
            {/* {props.match.params.animalTag}{" "}
            <button
              onClick={() =>
                setModalIsOpen(true)
              }
              className="btn-sm btn btn-success btn-fw float-right btn-rounded"
            >
              <i className="mdi mdi-account-plus mr-1"></i> Add Monthly Record
            </button> */}
          </>
        }>
              <div className="row">
                <div className="col-md-12 mb-5">
                  {this.state.step == 1 && (
                    <CardBlock title="">
                      <div className="row">
                        <div className="col-md-12 mb-0">
                          <form className="forms-sample">
                            <div className="form-group row">
                              <div className="col col-md-6 mx-auto">
                                <>
                                  <label className="control-label font-weight-bold text-darker mt-20">
                                    Animal Tag Number
                                  </label>
                                  <div className="input-group input-group-sm mb-3">
                                    <input
                                      type="text"
                                      onChange={(e) =>
                                        this.updateProp(e.target.value, "tagNumber")
                                      }
                                      className="form-control"
                                    />
                                    <div className="input-group-append">
                                      {this.state.validateLoading ? (
                                        <Spinner />
                                      ) : (
                                        <button
                                          onClick={() => {
                                            this.validateTagNumber();
                                            this.fetchBirthInformation();
                                            this.fetchMonthlyInformation();
                                          }}
                                          className="btn btn-success"
                                          type="button"
                                        >
                                          Fetch Animal
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </>
                              </div>
                            </div>
                            {this.state.animal && (
                              <>
                                <div className="form-group row">
                                  <div className="col-12 col-md-8 col-lg-8 mx-auto">
                                    <h5 className="theme-color mb-4 mt-4 row justify-content-between">
                                      <span> Animal's General Information</span>
                                      {this.state?.animalTag ? `${this.state?.animalTag}` : "-"}

                                    </h5>
                                    {this.animalBirthInformationDisplay()}
                                  </div>
                                </div>
                                <hr style={{ visibility: "hidden" }} />

                                <div className="row">
                                  {null && (
                                    <>
                                      <div
                                        style={{ zoom: "0.85", marginTop: "-20px" }}
                                        className="mb-3 col"
                                      >
                                        <div className="form-group row">
                                          <div className="col col-md-3">
                                            <label className="control-label font-weight-bold text-darker">
                                              Date from
                                            </label>
                                            <DatePickerInput
                                              defaultValue={this.state.fromDate}
                                              onChange={(date) =>
                                                this.setState({ fromDate: date }, () => {
                                                  this.quickReload();
                                                })
                                              }
                                              className="my-custom-datepicker-component"
                                            />
                                          </div>
                                          <div
                                            className="col text-center"
                                            style={{ maxWidth: "20px" }}
                                          >
                                            <label
                                              style={{ visibility: "hidden" }}
                                              className="control-label font-weight-bold text-darker"
                                            >
                                              -
                                            </label>
                                            <label className="control-label font-weight-bold text-darker mt-2">
                                              _
                                            </label>
                                          </div>
                                          <div className="col col-md-3">
                                            <label className="control-label font-weight-bold text-darker">
                                              Date to
                                            </label>
                                            <DatePickerInput
                                              defaultValue={this.state.toDate}
                                              onChange={(date) =>
                                                this.setState({ toDate: date }, () => {
                                                  this.quickReload();
                                                })
                                              }
                                              className="my-custom-datepicker-component"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <br style={{ clear: "both" }} />
                                    </>
                                  )}
                                  <div className="col-12 grid-margin">
                                    <div className="card">
                                      <div className="card-body">
                                        <div className="row">
                                          <div className="col-lg-12 col-sm-12 col-md-12 grid-margin  grid-margin-lg-0">
                                            <div className="wrapper pt-5">
                                              <div className="chartjs-size-monitor">
                                                <div className="chartjs-size-monitor-expand">
                                                  <div className />
                                                </div>
                                                <div className="chartjs-size-monitor-shrink">
                                                  <div className />
                                                </div>
                                              </div>
                                              <div className="text-wrapper d-flex align-items-center justify-content-between mb-0">
                                                <p className="mb-0 text-dark">Gross Weight at Slaughter</p>
                                              </div>
                                              <h3 className="mb-4 text-dark font-weight-bold">

                                                <small>KG:</small>      {this.state?.animalSlaughterInfo?.weight ? `${this.state?.animalSlaughterInfo?.weight}` : "-"}
                                              </h3>
                                              <div
                                                id="total-expences"
                                                style={{
                                                  display: "block",
                                                  width: "70%",
                                                  height: "auto",
                                                  margin: "auto"
                                                }}
                                                className="chartjs-render-monitor"
                                              >
                                                <Line
                                                  data={this.state.lineData}
                                                  options={this.lineOptions}
                                                />
                                              </div>
                                            </div>
                                          </div>

                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                {/* <div className="container-fluid page-body-wrapper">
          <div className="main-panel">
            <div className="content-wrapper">
                                <div className="content-wrapper mt-0"> */}

                                <div className="row">
            <div className="col-md-12 grid-margin stretch-card">
              <div className="w-100 card">
                <div className="card-body rounded">
                  <h4 className="mb-4">Animal Monthly Records</h4>
                  {/* <button
                    onClick={() =>
                      setModalIsOpen(true)
                    }
                    className="btn-sm btn btn-success btn-fw float-right btn-rounded"
                  >
                    <i className="mdi mdi-account-plus mr-1"></i> Add Monthly Record
                  </button> */}
                  <div className="table-responsive">


                  <RanchAnimalMonthlyList
                                            selectRecord={(r) => this.setSelectedRecord(r)}
                                            animalTag={this.state.payload?.tagNumber}
                                          />
                  </div>
                </div>
              </div>
            {/* </div>
            </div>
            </div>
            </div> */}
            </div>

          </div>


                                {console.log(this.state?.animalSlaughterInfo, this.state?.animalSlaughterInfo?.abattoirId)}
                                <h3>Animal Certificate</h3>
                                <object data={`https://proteintrail.com/api/Certificates/downloadcertificate/${this.state?.animalSlaughterInfo?.tagNumber}/${this.state?.animalSlaughterInfo?.createdBy}`} type="application/pdf" width="100%" height="500px">
                                </object>
                              </>
                            )}
                          </form>
                        </div>
                      </div>
                    </CardBlock>
                  )}


                </div>




              </div>
              </MainLayout>

            </div>

      </>
    );
  }
}
