import React, { Component, useContext } from "react";
import TopNav from "../TopNav";
import Sidebar from "../Sidebar";
import { Link, Redirect } from "react-router-dom";
import CenterBlock from "../CenterBlock";
import Spinner from "../../Utils/Spinner";
import { DataContext } from "../../Contexts/DataContexts";
import AddAppScripts from "./AddAppScripts";
import APICall from "../../Utils/APICall";
// ES6 Modules or TypeScript
import Swal from 'sweetalert2'

import Functions from "../../Utils/Functions";
import SpinnerButton from "../../Utils/SpinnerButton";
import Toastr from "../../Utils/Toastr";

export default class MainLayout extends Component {



  constructor(props) {
    super(props);
    this.state = {
      redirect: null,
      hideFloatingButton: false,
      hideActivationMessage: false,
      mobileMenuIsOpen: false,
      loggedInUser: null,
      loading: true,
      data: null,
      errorMessage: null,
      newRecord: {},
      newRecordErrorMessage: null,
      submitLoading: false,
      loggedInUser: null,
      profileImageloading: false,
      hovered: false,
      updatePayload: {},
      queryString: Functions.randomString(6),
    };

    this.toggleMobileMenu = this.toggleMobileMenu.bind(this);
  }

  static contextType = DataContext;

  componentDidMount() { }


  toggleMobileMenu() {
    this.setState({ mobileMenuIsOpen: !this.state.mobileMenuIsOpen });
  }



  toggleDialog() {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {

        //API CAll

        APICall("api/users/delete", "DELETE", { "email": this.state.data.email })
          .then((data) => {
            if (data.data.isSuccess) {
              Swal.fire(
                'Deleted!',
                'Your Account has been deleted.',
                'success'
              )
              setTimeout(() => window.location.href = '/login', 1000);
            } else {
              this.setState({ errorMessage: data.data.message, loading: false });
            }
          })
          .catch((error) => {
            this.setState({
              errorMessage: "An error occurred, please check your network",
              loading: false,
            });
          });


      }
    })
  }

  //#############################################

  componentDidMount() {
    this.whoIsLoggedIn();
  }

  whoIsLoggedIn = () => {
    this.setState({ errorMessage: null, loading: true });
    APICall("api/users/profile", "GET", {})
      .then((data) => {
        var response = data.data;
        if (response.isSuccess) {
          this.setState({ loggedInUser: response.data });
          this.init(this.state.page, this.state.pageSize);
          localStorage.setItem("profile", JSON.stringify(response.data));

        } else {
          this.setState({
            errorMessage:
              "Your request generated an error. Refresh or try again after sometime",
            loading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          errorMessage:
            "Your request generated an error. Please check your connection",
          loading: false,
        });
      });
  };

  init() {
    this.setState({
      errorMessage: null,
      loading: true,
    });

    var endpoint = "";
    if (this.props.userId) {
      endpoint = "api/users/get/" + this.props.userId;
    } else {
      endpoint = "api/users/profile";
    }

    APICall(endpoint, "GET", {})
      .then((data) => {
        var response = data.data;
        if (response.isSuccess) {
          var updatePayload = {
            name: response.data.name,
            phoneNumber: response.data.phoneNumber,
            vcnRegistrationNumber: response.data.vcnRegistrationNumber,
          };
          this.setState(
            {
              loading: false,
              data: response.data,
              updatePayload: updatePayload,
            },
            () => { }
          );
        } else {
          this.setState({
            errorMessage:
              "Your request generated an error. Try again after sometime",
            loading: false,
          });
        }
        console.log(data);
      })
      .catch((error) => {
        this.setState({
          errorMessage:
            "Your request generated an error. Please check your network connection",
          loading: false,
        });
      });
  }

  updateProp(e, prop) {
    var record = this.state.updatePayload;
    record[prop] = e.target.value;
    this.setState({ updatePayload: record }, () => { });
  }

  validate() {
    if (
      !this.state.updatePayload.name ||
      !this.state.updatePayload.phoneNumber
    ) {
      Toastr("error", "Name and phone number cannot be blank");
      return false;
    }
    if (this.state.updatePayload.name.trim() == "") {
      Toastr("error", "Name  cannot be blank");
      return false;
    }
    if (
      this.state.updatePayload.phoneNumber.trim() == "" ||
      this.state.updatePayload.phoneNumber.trim().length < 7
    ) {
      Toastr("error", "Add a valid mobile number");
      return false;
    }
    return true;
  }

  submit() {
    if (!this.validate()) return;
    this.setState({ submitLoading: true });
    var requestPayload = this.state.updatePayload;
    requestPayload.id = this.state.data.id;
    APICall("api/users/update", "POST", requestPayload)
      .then((data) => {
        var response = data.data;
        if (response.isSuccess) {
          this.setState({ submitLoading: false });
          Toastr("success", "User account updated");
          this.context.addItem("user", response.data);
        } else {
          Toastr("error", response.message);
          this.setState({ submitLoading: false });
        }
      })
      .catch((error) => {
        Toastr(
          "error",
          "Your request generated an error. Please check your network connection"
        );
        this.setState({ submitLoading: false });
      });
  }

  changePassword() {
    if (
      !this.state.newPassword ||
      !this.state.newPasswordConfirmation ||
      this.state.newPassword.trim() == "" ||
      this.state.newPasswordConfirmation.trim() == ""
    ) {
      return;
    }
    if (this.state.newPassword != this.state.newPasswordConfirmation) {
      Toastr("error", "Your new password and confirmation does not match");
      return;
    }

    this.setState({ passwordLoading: true });

    APICall("api/users/changepassword", "POST", {
      newPassword: this.state.newPassword,
    })
      .then((data) => {
        var response = data.data;
        if (response.status == "success") {
          Toastr("success", "Password changed, you will be logged out");
          setTimeout(() => {
            window.location.href = "/logout";
          }, 2000);
          this.setState({ newPassword: "", newPasswordConfirmation: "" });
        } else {
          Toastr("error", response.message);
          this.setState({ passwordLoading: false });
        }
      })
      .catch((error) => {
        Toastr(
          "error",
          "Your request generated an error. Please check your network connection"
        );
        this.setState({ passwordLoading: false });
      });
  }

  render() {
    return (
      <>
        {/* <AddAppScripts /> */}
        {/* <TopNav toggleMobileMenu={this.toggleMobileMenu} /> */}
        <div className="">
          {/* <Sidebar mobileMenuIsOpen={this.state.mobileMenuIsOpen} /> */}
          <div className="main-panel main-panel-2">
            <div className="content-wrapper">
              <div className={(this.props.title ? "" : "pt-0") + ""}>
                <div className="d-xl-flex justify-content-between align-items-start" style={{ display: 'flex' }}>
                  {this.props.title ? (
                    <h3 className="text-dark font-weight-bold mb-4 w-100 ml-3">
                      {this.props.title}
                    </h3>
                  ) : null}
                  {/* {this.props.title ? (


                    <button
                      style={{ textAlign: 'center' }}
                      type="button"
                      className="btn btn-block alert-danger"
                      onClick={() => this.toggleDialog()}
                    >
                      Delete my account
                    </button>
                  ) : null} */}

                  <nav aria-label="breadcrumb" style={{ display: "none" }}>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="#">Account Settings</a>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Operation Addresses
                      </li>
                    </ol>
                  </nav>
                </div>
                {this.state.loading ? (
                  <CenterBlock height="150">
                    <Spinner size="2" />
                  </CenterBlock>
                ) : this.state.redirect ? (
                  <Redirect to={this.state.redirect} />
                ) : (
                  this.props.children
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
